/* Heads up! The Norwegian symbols ae oe aa will cause parser issues that -will not- trigger errors (silent). Keep all comments in English! 0-9, a-z. */
:root {
  --aside-bg: var(--color-true-white, white);
}

// Define a reusable function for mobile landscape style
@mixin landscapemobile {
  @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
    position: inherit;
    margin-block: 1rem;
    transform: translateX(0);
  }
}

/*
Reset / normalize 

Based off
https://github.com/hankchizljaw/modern-css-reset
https://www.joshwcomeau.com/css/custom-css-reset/
https://meyerweb.com/eric/tools/css/reset/
*/

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
* {
  margin: 0;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body,
#__next {
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  background-color: var(--color-bg, var(--color-true-white));
  color: var(--color-txt, var(--color-black));
}

body {
  /*height: 100vh;*/
  /*height: -webkit-fill-available;*/
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-family);
  font-size: var(--font-size-medium);
  line-height: var(--line-height, 1.5);
  overscroll-behavior: contain;
}

html:focus-within {
  scroll-behavior: smooth;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
button {
  min-width: 40px;
  min-height: 40px;
}

/* Avoid text overflows */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

fieldset {
  border: none;
}

#__next,
#__next {
  isolation: isolate;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

[hidden] {
  display: none;
}

h1 {
  font-size: var(--font-size-xlarge);
}
h2 {
  font-size: var(--font-size-large);
}
h3 {
  font-size: var(--font-size-medium);
}
h4 {
  font-size: var(--font-size-small);
}

p {
  line-height: 125%;
}

#__next {
  display: grid;
  grid: [stack] 1fr / min-content [stack] 1fr;
  min-block-size: 100%;
  overflow-x: hidden;
}
#__next > :is(aside, main) {
  grid-area: stack;
}
#__next > main {
  display: flex;
  //background-color: var(--color-bg);
  height: 100dvh;
  margin: 0 auto;
  width: 100vw;
  flex-direction: column;
  overflow-y: auto;
  //overflow-x: hidden;
}
@supports (height: 100dvh) {
  #__next {
    height: 100dvh;
    min-block-size: 100dvh;
  }
  #__next > main {
    height: 100dvh;
  }
}

#__next > main > header {
  //background-color: var(--header-bg);
  display: flex;
  //justify-content: space-between;
  //height: 2rem;
  margin: 0 auto;
  //overflow-x: hidden;

  width: 100%;
}

#__next > main > article {
  //margin-block-start: -1rem;
  //padding-inline: 1rem;
  width: 100%;
  //height: 100%;
  //background-color: transparent;
  //overflow-x: hidden;
  //overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  //background-color: white;
  background-color: var(--color-bg);

  & > div > div > * {
    max-width: var(--article-max-width);
    //margin-inline: auto;
  }
}
@media (max-height: 25rem) {
  #__next > main > article {
    height: unset;
    overflow: revert;
    overflow-x: clip;
  }
}

#__next > main > footer {
  grid-area: footer;
  background-color: var(--footer-bg);
  border-top: 1px solid rgba(125, 125, 125, 0.1);
  /*box-shadow: 0 5px 15px rgba(0,0,0,0.2);*/
  display: flex;
  justify-content: space-between;
}

header > aside {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  visibility: hidden;
  // transform: translateX(-100vw);
  will-change: transform;
  backdrop-filter: blur(5px);
  background-color: hsla(0, 0%, 3%, 0.5);
  z-index: 3;
  transition: 100ms ease-in-out;
}

@media (max-aspect-ratio: 1/1), (max-height: 25rem) {
  header > aside {
    width: 100vw;
  }
  header {
    aside {
      nav {
        width: 100vw;
        height: 99.95vh;
      }
    }
  }
}

header {
  aside {
    nav {
      background-color: var(--color-aside-bg);
      display: flex;
      flex-direction: column;
      padding: 0rem 3rem;
      line-height: 3rem;
      width: 100%;

      @media screen and (orientation: landscape) {
        max-width: 30rem;
      }
      a {
        text-decoration: none;
        color: var(--black);

        &:focus-visible {
          outline: var(--outline);
        }
      }

      &:focus-visible {
        outline: var(--outline-nav);
      }
    }
  }
}

header > aside.open {
  visibility: visible;
  // transform: translateX(0);
  z-index: 150;
}

#sidenav-button,
#sidenav-close {
  background-color: rgba(125, 125, 125, 0.2);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

/*
CUBE CSS
*/

/*
Composition
*/

.flow > * + * {
  margin-top: var(--flow-space, 1em);
}

/*
Utlitity
*/
.wrapper {
  margin-inline: auto;
  padding-inline: 1rem;
  max-width: 60rem;
}

a {
  color: inherit;
  font-family: var(--font-family-headings);
  font-weight: bold;
  &:focus-visible {
    outline: var(--outline);
  }
}
a.btn {
  border-radius: var(--border-radius-default);
  border-width: var(--border-width-default, 0px);
  border-style: var(--border-style, solid);
  text-decoration: none;
  display: block;
  text-align: center;
  &:hover {
    color: var(--button-hover-color) !important;
    background-color: var(--button-hover-background-color) !important;
    border-color: var(--button-hover-border-color) !important;
    cursor: pointer;
  }

  &.primary {
    color: var(--button-primary-color);
    border: var(--button-primary-border);
    background-color: var(--button-primary-background-color);
    font-family: var(--button-primary-font-family);
    font-weight: var(--button-primary-font-weight);
    font-size: var(--button-medium-font-size);
    padding-block: var(--button-medium-padding-block);
    padding-inline: var(--button-medium-padding-inline);
    width: var(--button-medium-width);
  }
  &.secondary {
    color: var(--button-secondary-color);
    border: var(--button-secondary-border);
    background-color: var(--button-secondary-background-color);
    font-family: var(--button-secondary-font-family);
    font-weight: var(--button-secondary-font-weight);
    font-size: var(--button-medium-font-size);
    padding-block: var(--button-medium-padding-block);
    padding-inline: var(--button-medium-padding-inline);
    width: var(--button-medium-width);
  }

  &.small {
    font-size: var(--button-xsmall-font-size);
    padding-block: var(--button-small-padding-block);
    padding-inline: var(--button-small-padding-inline);
    width: var(--button-small-width);
  }
  &.medium {
    font-size: var(--button-medium-font-size);
    padding-block: var(--button-medium-padding-block);
    padding-inline: var(--button-medium-padding-inline);
    width: var(--button-medium-width);
  }

  &.large {
    font-size: var(--button-large-font-size);
    padding-block: var(--button-large-padding-block);
    padding-inline: var(--button-large-padding-inline);
    width: var(--button-large-width);
  }
}

.error {
  color: red;
}

input {
  background-color: white;
  border: 2px solid transparent;
}
input:focus {
  border: 2px solid transparent;
}
input:focus-visible {
  outline: var(--outline);
  outline-offset: var(--outline-offset);
}
