/* Regular */
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("montserrat-regular.woff2") format("woff2");
}

/** Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("montserrat-bold.woff2") format("woff2");
}

/** Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("montserrat-italic.woff2") format("woff2");
}


/** Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("montserrat-bolditalic.woff2") format("woff2");
}

