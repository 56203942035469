:root {
  /* Heads up! The Norwegian symbols ae oe aa will cause parser issues that -will not- trigger errors (silent). Keep all comments in English! 0-9, a-z. */
  color-scheme: light;

  /********** FONTS **********/

  --font-family-headings: "Montserrat";
  --font-family: "Roboto";

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --questionText-weight: var(--font-weight-semibold);

  --line-height: 1.5;
  /* calculated with: https://www.marcbacon.com/tools/clamp-calculator/ */
  /* using 400px as lower limit and 1024 as upper limit for scaling */
  /*
  --font-size-xxsmall: clamp(0.625rem, 0.545rem + 0.321vw, 0.75rem); //10-12px
  --font-size-xsmall: clamp(0.75rem, 0.67rem + 0.321vw, 0.875rem); //12-14px
  --font-size-small: clamp(0.875rem, 0.795rem + 0.321vw, 1rem); //14-16px
  --font-size-medium: clamp(1rem, 0.92rem + 0.321vw, 1.125rem); //16-18px
  --font-size-large: clamp(1.125rem, 1.045rem + 0.321vw, 1.25rem); //18-20px
  --font-size-xlarge: clamp(1.25rem, 1.09rem + 0.641vw, 1.5rem); //20-24px
  --font-size-xxlarge: clamp(1.5rem, 1.179rem + 1.282vw, 2rem); //24-32px
  */

  /* testing to see if we can use same sizes on mobile and desktop */
  --font-size-xxsmall: 0.75rem; //10-12px
  --font-size-xsmall: 0.875rem; //12-14px
  --font-size-small: 1rem; //14-16px
  --font-size-medium: 1.125rem; //16-18px
  --font-size-large: 1.25rem; //18-20px
  --font-size-xlarge: 1.5rem; //20-24px
  --font-size-xxlarge: 2rem; //24-32px

  /********** COLORS **********/

  /* HSL-based to make it easier to adjust with custom properties 

  Hues: 
  0 - red
  60 - yellow
  120 - green
  180 - cyan
  240 - blue
  300 - magenta

  Saturation: 
  0 - gray
  100 - full color

  Lightness:
  0 - black
  50 - middle
  100 - white

  */

  /* colors */
  --color-true-black: black;
  --color-true-white: white;

  --color-black: var(--color-true-black);
  --color-txt: hsl(206, 8%, 17%); // #282C2F
  --color-white: hsl(52, 100%, 95%); // #FFFCE8

  --color-wrong: hsl(0, 95%, 71%); // #FB6E6E
  --color-correct: hsl(145, 100%, 80%); // #9BFFC5

  --color-highlight-1: hsl(47, 100%, 50%); // #FFC700
  --color-highlight-2: hsl(166, 51%, 50%); // #3FC1A2
  --color-highlight-3: hsl(1, 75%, 76%); // #F09694

  --color-bg-hue: 166;
  --color-bg-hsl-values: var(--color-bg-hue), 51%, 50%;
  --color-topmenu-bg: hsl(var(--color-bg-hsl-values));
  --color-panelmenu-bg: hsl(var(--color-bg-hsl-values));
  --color-bg: #3fc1a2; //hsl(var(--color-bg-hsl-values)); // #3FC1A2
  --color-bg-dark: hsl(var(--color-bg-hue), 0%, 72%);

  --color-box-fill: hsl(37, 50%, 93%); // #F6EFE4
  --color-card-fill: hsl(198, 100%, 45%); // #009FE3
  --color-hover-fill: hsl(41, 100%, 75%); // #FFD881

  --color-btn-stroke: hsl(212, 68%, 17%); // #0E2A49
  --color-btn-primary-fill: hsl(0, 0%, 20%); // #333333
  --color-btn-primary-txt: var(--color-true-white);
  --color-btn-primary-hover-fill: var(--color-hover-fill);
  --color-btn-primary-hover-txt: var(--color-true-black);
  --color-btn-primary-active-fill: var(--color-hover-fill);
  --color-btn-primary-active-txt: var(--color-true-black);
  --color-btn-primary-disabled-fill: hsl(0, 0%, 58%); // #959595
  --color-btn-primary-disabled-txt: var(--color-true-black);

  --color-btn-secondary-fill: var(--color-btn-primary-fill);
  --color-btn-secondary-txt: var(--color-true-white);
  --color-btn-secondary-hover-fill: var(--color-btn-primary-hover-fill);
  --color-btn-secondary-hover-txt: var(--color-true-black);
  --color-btn-secondary-active-fill: var(--color-btn-primary-active-fill);
  --color-btn-secondary-active-txt: var(--color-true-black);
  --color-btn-secondary-disabled-fill: var(--color-btn-primary-disabled-fill);
  --color-btn-secondary-disabled-txt: var(--color-true-black);

  --color-default-interactive-fill: hsl(206, 8%, 17%); // #282C2F

  --color-btn-mc-fill: var(--color-default-interactive-fill);
  --color-btn-mc-txt: hsl(0, 0%, 100%); // #FFFFFF
  --color-btn-mc-hover-fill: var(--color-hover-fill);
  --color-btn-mc-hover-txt: hsl(0, 0%, 0%); // #000000
  --color-btn-mc-active-fill: var(--color-highlight-2);
  --color-btn-mc-active-txt: hsl(0, 0%, 0%); // #000000
  --color-btn-mc-disabled-fill: hsl(0, 0%, 63%); // #A2A2A2

  --color-aside-bg: hsl(7, 68%, 80%);
  --color-aside-txt: var(--color-txt);
  --color-aside-hover-background: var(--button-hover-background-color);

  /******** SHADOWS **********/

  --shadow-hsl-values: var(--color-bg-hue), 0%, 47%;

  //--primary-bg-drop-shadow: drop-shadow(0 4px 0 rgba(0, 0, 0, 0.15));

  /* offset-x | offset-y | blur-radius | spread-radius | color */
  --box-shadow: 3px 3px red, -1em 0 0.4em olive;
  --text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;
  /* offset-x | offset-y | blur-radius | color */
  --filter: drop-shadow(0 0 0.75rem crimson);

  /******** BORDERS ********/

  --border-radius-small: 3px;
  --border-radius-medium: 5px;
  --border-radius-large: 10px;
  --border-radius-full: 9999px;

  --border-radius-default: var(--border-radius-full);

  /* top | right | bottom | left */
  --primary-border-radius: 10% 30% 50% 70%;
  --primary-border-width: 3px 3px 3px 3px;
  --primary-border-style: dashed groove none dotted;
  --primary-border-color: red yellow green hsla(60, 90%, 50%, 0.8);
  /* width | style | color */
  --border: medium dashed green;

  --outline: 3px dashed #32a1ce;
  --outline-nav: 6px dashed #32a1ce;
  --outline-light: 3px dashed #ffffff;
  --outline-offset: 3px;

  /******** SPACING ********/

  --spacing-small: 0.75rem;
  --spacing-medium: 1.5rem;
  --spacing-large: 2rem;
  --spacing-xlarge: 2.67rem;
  --spacing-xxlarge: 3.55rem;
  --spacing: var(--spacing-medium);

  --padding-small: 0.75rem;
  --padding-medium: 1.5rem;
  --padding-large: 2rem;
  --padding-xlarge: 2.67rem;
  --padding-xxlarge: 3.55rem;
  --padding: var(--padding-medium);
  --padding-top: 1rem;

  --primaryH: 78;
  --primaryS: 100%;
  --primaryL: 42%;
  --secondaryH: 208;
  --secondaryS: 14%;
  --secondaryL: 21%;

  --margin-small: 4px;
  --margin-small: 8px;
  --margin-medium: 16px;
  --margin-large: 18px;
  --margin-block: 0.6rem;

  /*
* Breakpoints
*/
  --zoom-breakpoint: 30rem; // max-height(--zoom-breakpoint)
  --portrait-layout-breakpoint: 1/1; // max-aspect-ratio(--portrait-layout-breakpoint)

  /*
  * Input
  */

  /** Input type="search" **/

  // Must be inline SVG of Choice or else it won't work. Don't ask me why cause I don't know.
  --input-search-cancel-button-icon: url('data:image/svg+xml;utf8,<svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.20015 23.3429L23.7997 1.74292" stroke="currentColor" stroke-width="3" stroke-linecap="round"/><path d="M23.7998 23.3429L2.20023 1.74292" stroke="currentColor" stroke-width="3" stroke-linecap="round"/></svg>');

  /*
  * Article
  */
  --article-max-width: 56.25rem;
  --article-narrow-max-width: 37.5rem;

  /*
  * Header
  */
  --header-bg: var(--color-true-white);
  --header-bg-color: var(--color-txt);
  --header-btn-padding: 0;
  --header-btn-bg-color: transparent;
  --header-btn-border-radius: var(--border-radius-medium);
  --header-btn-margin-inline: 0.5rem; //var(--margin-medium);
  --header-btn-margin-block: 0.5rem; //var(--margin-small);
  --header-btn-inner-width: 3rem; //auto;
  --header-btn-inner-height: 3rem; //3rem;
  --header-btn-inner-padding-inline: 0.2rem;
  --header-btn-inner-hover-color: var(--color-hover-fill);
  --header-svg-color: var(--color-white);
  --header-svg-hover-color: var(--color-txt);
  --header-svg-close-color: var(--color-txt);
  --header-text-color: var(--color-true-white);

  /*
  * Footer
  */
  --footer-bg: #303030;
  --footer-padding-portrait: 1.5rem;
  --footer-padding-landscape: 2rem;

  /*
  * Aside
  */
  --aside-btn-border-radius: none;

  /*
  * Progressbar
  */
  --progressbar-bg-color: var(--color-box-fill);
  --progressbar-box-shadow: none;
  --progressbar-fill-color: var(--color-highlight-3);

  /*
  * Notifications
  */
  --notifications-background: transparent;
  --notifications-bg-line: var(--color-txt);
  --notifications-background-before: transparent;
  --notifications-background-after: transparent;
  --notifications-background-content: var(--color-box-fill);
  --notifications-content-color: var(--color-true-black);
  --notifications-content-box-shadow: none;
  --notification-content-width: 6rem;

  /********** BUTTONS **********/

  --button-small-font-size: var(--font-size-small);
  --button-small-padding-block: 0.2rem;
  --button-small-padding-inline: 0.2rem;
  --button-small-width: 5.5rem;

  --button-medium-font-size: var(--font-size-medium);
  --button-medium-padding-block: 0.5rem;
  --button-medium-padding-inline: 0.3rem;
  --button-medium-width: 7.5rem;

  --button-large-font-size: var(--font-size-large);
  --button-large-padding-block: 0.7rem;
  --button-large-padding-inline: 1.25rem;
  --button-large-width: 13.5rem;

  --button-xlarge-font-size: var(--font-size-xlarge);

  --button-hover-color: var(--color-true-black);
  --button-hover-background-color: var(--color-hover-fill);
  --button-hover-border-color: var(--color);

  --button-thickness: 3px; //4px;
  --button-disabled-color: rgba(255, 255, 255, 0.5); //var(--color-true-white);
  --button-disabled-border: var(--button-thickness) solid transparent;
  --button-disabled-background-color: #bfbeb1;

  --button-focus-visible-outline: var(--outline, 2px dashed blue);
  --button-focus-visible-outline-offset: var(--outline-offset, 2px);

  --fake-button-focus-visible-outline: 3.5px solid var(--color-txt);
  --fake-button-focus-visible-outline-offset: 3.5px;

  --button-primary-color: var(--color-btn-primary-txt);
  --button-primary-background-color: var(--color-btn-primary-fill);
  --button-primary-filter: var(--primary-bg-drop-shadow, none);
  --button-primary-border: var(--button-thickness) solid transparent;
  --button-primary-font-family: var(--font-family-headings, inherit);
  --button-primary-font-weight: var(--font-weight-bold);

  --button-secondary-color: var(--color-btn-secondary-txt);
  --button-secondary-background-color: var(--color-btn-secondary-fill);
  //--button-secondary-border-color: var(--secondary-border);
  --button-secondary-border: var(--button-thickness) solid transparent;
  --button-secondary-font-family: var(--font-family-headings, inherit);
  --button-secondary-font-weight: var(--font-weight-regular);

  --button-ghost-color: var(--color-btn-secondary-txt);
  --button-ghost-background-color: transparent;
  --button-ghost-border-color: transparent;

  --button-negative-color: var(--color-true-black);
  --button-negative-background-color: var(--color-wrong);
  --button-negative-filter: var(--button-primary-filter, none);
  --button-negative-font-family: var(--button-primary-font-family);
  --button-negative-font-weight: var(--button-primary-font-weight);

  --button-accordion-color: var(--color-true-black);
  --button-accordion-border-radius: var(--border-radius-medium);
  --button-accordion-background-color: var(--color-true-white);
  --button-accordion-hover-background-color: var(--color-txt);
  --button-accordion-hover-color: var(--color-true-white);

  --button-status-color: var(--color-true-black);

  --button-default-color: var(--color-true-black);

  //bottom navbuttons (next, repeat)
  --navbuttons-container-background-color: hsl(
    var(--color-bg-hsl-values),
    0.75
  );
  --navbuttons-container-padding-bottom: 2rem;

  /*
  * ModuleMenu and courses
  */
  --courses-linear-gradient: linear-gradient(
    to bottom,
    rgba(40, 44, 47, 0.85) 50%,
    rgba(40, 44, 47, 1) 75%
  );
  //--courses-bg-image: url("../public/assets/images/bhg-bg.png");
  --courses-min-height: fit-content;
  --courses-content-color: var(--color-true-white);
  --courses-content-color-desktop: var(--color-true-black);
  --courses-content-transform: translateY(-2rem);
  --courses-title-color: var(--color-true-white);
  --courses-imageGrid-max-height: 73.875rem; // 1182px

  --mm-btn-container-bg-color: var(--color-box-fill);
  --mm-btn-container-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  --mm-btn-container-border-radius: 24px;
  --mm-btn-container-gap: 0.55rem;
  --mm-btn-container-padding: 1rem;
  --mm-box-shadow: none; //0px 3px 0px rgba(0, 0, 0, 0.1);
  --mm-box-border: var(--button-thickness) solid transparent;
  --mm-box-border-hover: var(--button-thickness) solid var(--color-txt);
  --mm-border-radius: 12px;
  --mm-font-size: var(--font-size-small);
  --mm-font-weight: var(--font-weight-semibold);
  --mm-item-bg-color: hsl(0, 0%, 63%);
  --mm-btn-number-width: 28px;
  --mm-btn-number-height: 28px;
  --mm-icon-font-size: 22px;
  --mm-icon-margin-left: 10px;
  --mm-icon-padding: 0; //4px;
  --mm-icon-border-radius: 50%;
  --mm-icon-bg-color: var(--color-txt); //var(--color-btn-stroke);
  --mm-icon-color: hsl(0, 0%, 63%);
  --mm-icon-finished-bg-color: var(--color-correct);
  --mm-icon-finished-color: var(--color-txt);
  --mm-icon-locked-bg-color: var(--color-txt);
  --mm-icon-locked-color: hsl(0, 0%, 63%);
  --mm-icon-incomplete-bg-color: var(--color-txt);
  --mm-icon-incomplete-color: var(--color-true-white);
  --mm-icon-current-bg-color: var(--color-txt);
  --mm-icon-current-color: var(--color-true-white);
  --mm-button-completed-bg-color: var(--color-txt);
  --mm-button-completed-color: var(--color-true-white);
  --mm-button-unlocked-bg-color: var(--color-highlight-2);
  --mm-button-unlocked-color: var(--color-true-black);
  --mm-button-incomplete-bg-color: var(--color-highlight-2);
  --mm-button-incomplete-color: var(--color-true-black);
  --mm-button-current-bg-color: var(--color-highlight-1);
  --mm-button-current-color: var(--color-true-black);
  --mm-button-current-border: var(--button-thickness) solid var(--color-txt);
  --mm-button-locked-color: var(--color-true-black);
  --mm-explanationicon-font-size: 22px;
  --mm-explanation-icon-padding: 0;
  --mm-explanation-icon-margin-right: 0.5rem;
  --mm-explanation-icon-border-radius: 50%;
  --mm-explanation-icon-bg-color: var(--color-true-white);
  --mm-explanation-icon-color: var(--color-txt);

  /*
  * Modal
  */
  --modal-background-color: rgba(0, 0, 0, 0.5);
  --modalContent-background-color: var(--color-true-white);
  --modalContent-width: clamp(15rem, 95%, 20rem);
  --modal-text-color: var(--color-txt);
  --modalContent-padding: 0.5rem;
  --modalContent-box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.15);
  --modalContent-border-radius: 12px;
  --modal-heading-size: var(--font-size-large);
  --modal-text-size: var(--font-size-small);
  --modal-text-padding-top: 1rem;
  --modal-text-line-height: 150%;
  --modalBtnContainer-width: clamp(14rem, 100%, 20rem);
  --modalBtnContainer-margin: 1rem auto 0.5rem;
  --modal-exit-button: none;
  --modal-exit-icon-color: var(--color-true-black);
  --modal-exit-icon-close: url("/assets/images/menu-close-modal.svg");

  /*
  * Accordion
  */
  --accordion-outline: none;
  --accordion-background: transparent;
  --accordion-item-outline: none;
  --accordion-item-background: transparent;
  --accordion-header-outline: none;
  --accordion-header-background: transparent;
  --accordion-content-outline: none;
  --accordion-content-background: transparent;
  --accordion-open-background-color: var(--color-txt);
  --accordion-open-color: var(--color-true-white);

  /*
  * FreeTextInput
  */
  --container-text-weight: var(--font-weight-semibold);
  --container-text-max-width: var(--article-narrow-max-width);

  --textarea-font-size: var(--font-size-small);
  --textarea-box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.3), 0px 2px 0px #e2e2e2;
  --textarea-border-radius: 8px;
  --textarea-background-color: var(--color-true-white);
  --textarea-color: var(--color-txt);
  --textarea-width: clamp(15rem, 100%, var(--article-narrow-max-width));
  --textarea-height: clamp(6rem, 10vh, 14rem);
  --textarea-scrollbar-width: 20px;
  --textarea-scrollbar-track-bgcolor: #9fe0d0;
  --textarea-scrollbar-track-border-radius: 0px 8px 8px 0px;
  --textarea-scrollbar-thumb-background: #3fc1a2;
  --textarea-scrollbar-thumb-border-radius: 0px 5px 5px 0px;

  /*
  * Rating
  */
  --ra-container-maxWidth: var(--article-narrow-max-width);
  --ra-checked-background-color: var(--color-txt);
  --ra-checked-font-color: var(--color-true-white);
  --ra-checked-width: 3rem;
  --ra-checked-border: var(--button-thickness) solid var(--color-true-black);
  --ra-label-width: 3rem;
  --ra-label-font-family: var(--font-family);
  --ra-label-background-color: var(--color-bg);
  --ra-label-color: var(--color-true-black);
  --ra-label-font-weight: 700;
  --ra-label-padding: 0.65rem;
  --ra-label-border: var(--button-thickness) solid transparent;
  --ra-label-border-radius: 8px;
  --ra-label-transition: 0.025s ease-in;
  --ra-label-active-box-shadow: none;
  --ra-label-active-translate: 0 6px;
  --ra-label-hover: var(--color-hover-fill);
  --ra-label-hover-color: var(--color-true-black);

  /*
  *   PAGES
  */

  /** Landingpage **/
  --landingPage-font-family: var(--font-family-headings);
  --landingPage-font-weight: var(--font-weight-bold);

  /** Manager **/
  --manager-background-color: var(--color-true-white);
  --manager-border-radius: var(--border-radius-large);

  /** Mentees **/
  --mentees-navBtn-width: clamp(6.55rem, 30%, 10rem);
  --mentees-navBtn-border: var(--button-thickness) solid transparent;
  --mentees-navBtn-border-radius: 8px;
  --mentees-navBtn-hover-background-color: var(--color-hover-fill);
  --mentees-navBtn-hover-border: var(--button-thickness) solid var(--color-txt);
  --mentees-navBtn-hover-color: var(--color-true-black);
  --mentees-navBtn-active-background-color: var(--color-hover-fill);
  --mentees-navBtn-active-border: var(--button-thickness) solid var(--color-txt);
  --mentees-navBtn-active-color: var(--color-true-black);
  --mentees-user-border-top: 1px solid rgb(98, 98, 98);
  --mentees-courses-background-image: none;
  --mentees-article-background-color: var(--color-txt);
  --mentees-navBtn-active-module-border: var(--button-thickness) solid
    var(--color-hover-fill);
  --mentees-note-border: 2px solid var(--color-txt);
  --mentees-note-border-radius: 8px;
  --mentees-note-background-color: transparent;
  --mentees-outline: var(--outline);
  --mentees-outline-offset: var(--outline-offset);

  /** Profile **/
  --profile-background-color: #9fe0d0;
  --profile-color: var(--color-true-black);
}

/*--- USING THE CUSTOM PROPS ---*/
//@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap");
//@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
//note: these local fonts are slightly different in rendering compared the commented out imports above...also, why doesn't assets/font work?
//@import url("../../public/assets/font/montserrat/fontfaces.scss");
//@import url("../../public/assets/font/roboto/fontfaces.scss");

html {
  scroll-behavior: smooth;
}

html,
body {
  font-family: var(--font-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-headings);
}

blockquote {
  border-left: 10px solid var(--primary-bg);
  margin: 1.5em 10px;
  padding: 0.5em 20px;
  font-size: var(--font-size-medium);
}

.textpageImage {
  position: relative;
  width: 100%;
  max-width: var(--max-desktop-width);
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*
Custom class for question on exercises
*/
.question {
  font-family: var(--font-family, sans-serif);
  font-weight: var(--font-weight-semibold);
}

input {
  border-radius: 6px;
}

/*
img {
  width: 100%;
  max-width: 30rem !important;
  margin: 0.5rem 1rem;
}
*/
