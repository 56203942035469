/* Regular */
@font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url("roboto_regular.woff");
}

/** Bold **/
@font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("roboto_bold.woff");
}

/** Italic **/
@font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url("roboto_italic.woff");
}

/** Bold-Italic **/
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("roboto_bolditalic.woff");
}